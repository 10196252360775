






































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'ScannerForm',
  props: {
    foundedRebox: {
      type: String,
    },
    center: {
      type: Boolean,
    },
  },
  data() {
    return {
      reboxNumber: '',
      regularExpression: '/boxes/([a-zA-Z0-9]{16})/*',
      boxCodeFormat: '([a-zA-Z0-9]{16})/*',
      submitWasHandled: false,
      timer: 0
      ,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    ...mapState('globals', ['isBoxRegistrationComponent']),
    orderId(): string {
      const {
        params: { orderId },
      } = this.$route;
      return orderId;
    },
  },
  methods: {
    submitEmit() {
      this.$emit('reboxSearch', this.reboxNumber);
    },
  },
  watch: {
    foundedRebox: {
      handler(val: string) {
        this.reboxNumber = val;
      },
    },
    reboxNumber: {
      handler() {
        const REBOX_CODE_LENGTH = 16;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (this.reboxNumber.length === REBOX_CODE_LENGTH) {
            const matches = this.reboxNumber.match(this.boxCodeFormat);
            if (matches) {
              this.submitEmit();
              if (this.$refs.scannerField && this.isBoxRegistrationComponent) {
                (this.$refs.scannerField as any).reset();
              }
              return;
            }
          }
          if (this.reboxNumber.length > REBOX_CODE_LENGTH) {
            const match = this.reboxNumber.match(this.regularExpression);
            if (match) {
              this.reboxNumber = match[1]; // eslint-disable-line
            }
          }
        }, 100);
      },
    },
  },
});
